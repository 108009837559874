<template>
  <main class="participant-page layout__main">
    <Header
      @clickBackButton="$goBack(backLink)"
      :scrolledOffset="16"
      isWide
      :backButtonProps="backButtonProps"
    >
      <template v-slot:service>
        <div class="d-md-none" v-if="participant.name">
          <div class="bib-number">
            <div class="bib-number__avatar">
              <Avatar
                class="ml-auto mr-auto"
                :username="participant.name"
                :img="participant.avatar"
                :size="32"
              ></Avatar>
            </div>
            <div class="bib-number__val" v-if="participant.startNumber">№{{ participant.startNumber }}</div>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <Button
          class="d-none d-md-flex"
          shape="circle"
          variant="secondary"
          icon-left="upload"
          @click="$root.$modals.participantShareModal.open()"
        ></Button>
        <Button
          id="about-participant"
          class="d-md-none"
          shape="square"
          variant="clear"
          icon-size="xl"
          icon-left="more-vertical"
        ></Button>
        <Dropdown
          trigger-id="about-participant"
          placement="bottom-end"
          hide-overlay
          auto-width
          strategy="fixed"
        >
          <template slot="items">
            <div class="base-dropdown__item">
              <a
                class="base-dropdown__link"
                href="#"
                @click.prevent="$emit('openParticipantDetails')"
              >
                <span class="base-dropdown__text">
                  {{ !isRelay ? $t('participant.about_participant') : $t('participant.about_team') }}
                </span>
              </a>
            </div>
            <div class="base-dropdown__item">
              <a
                class="base-dropdown__link"
                href="#"
                @click="$root.$modals.participantShareModal.open()"
              >
                <span class="base-dropdown__text">{{ $t('participant.share') }}</span>
              </a>
            </div>
          </template>
        </Dropdown>
      </template>
    </Header>
    <div class="rr-header-offset"></div>
    <Loader v-if="isLoading"></Loader>
    <section class="participant-page__section section_pt section_pb" v-else>
      <div class="container">
        <Row vertical-gutters>
          <!-- Profile : BEGIN -->
          <Col :size="{ lg: 'auto' }">
            <div class="participant-page__profile">
              <Row vertical-gutters>
                <Col>
                  <div class="container__mobile">
                    <div class="block block_sm block_wide">
                      <Row class="participant-about">
                        <Col
                          class="participant-about__avatar text-center d-none d-md-block"
                          :size="{ md: 'auto', lg: 'stretch' }"
                        >
                          <a
                            class="participant-about__avatar-link"
                            :is="participant.profileLink ? 'a' : 'div'"
                            :href="participant.profileLink"
                          >
                            <Avatar
                              class="ml-auto mr-auto"
                              :username="participant.name"
                              :img="participant.avatar"
                              :size="96"
                              bordered
                            ></Avatar>
                          </a>
                          <div class="bib-number bib-number_sm" v-if="participant.startNumber">
                            <div class="bib-number__val">№{{ participant.startNumber }}</div>
                          </div>
                        </Col>
                        <Col :size="{ default: 'stretch', lg: '24' }">
                          <Row class="section__item">
                            <Col :size="{ md: 'stretch', lg: '24' }">
                              <div class="participant-about__name">
                                <div class="text-sm_mt d-none d-lg-block"></div>
                                <a
                                  class="participant-about__name-link"
                                  :class="{ 'uppercase': theme === 'bfla' }"
                                  :is="participant.profileLink ? 'a' : 'div'"
                                  :href="participant.profileLink"
                                >
                                  <h1 class="h3">{{ participant.name }}</h1>
                                </a>
                                <div class="text-with-icon text-sm text-sm_mt" v-if="participant.category">
                                  <Icon
                                    class="icon color-muted"
                                    name="pie-chart"
                                    size="s"
                                  />&nbsp;<span> {{ $t('result.category') }} <b>«{{ participant.category }}»</b></span>
                                </div>
                                <div class="text-sm text-sm_mt" v-if="participants.length">
                                  <div class="participant-about__members" @click.prevent="$emit('openParticipantDetails')">
                                    <a
                                      class="participant-about__members-item"
                                      v-for="(item, i) in participants.slice().reverse()"
                                      :key="i"
                                      :is="item.profileLink ? 'a' : 'div'"
                                      :href="item.profileLink"
                                      :title="item.name"
                                    >
                                      <Avatar
                                        :username="item.name"
                                        :img="item.avatar"
                                        :size="40"
                                        bordered
                                      ></Avatar>
                                    </a>
                                  </div>
                                </div>
                                <div class="h3_mb d-md-none d-lg-block"></div>
                              </div>
                            </Col>
                            <Col v-if="!visibilityCompareButton" :size="{ md: 'auto', lg: 'stretch' }">
                              <Button
                                v-show="!follow"
                                variant="secondary"
                                icon-left="compare-2"
                                :text="$t('participant.add_to_comparison')"
                                @click="compareResult"
                                mobile
                              ></Button>
                              <Button
                                v-show="follow"
                                variant="secondary"
                                view="success"
                                :text="$t('participant.in_comparison')"
                                mobile
                                :to="{ name: 'Compare', params: { eventCode: event.code, raceCode: race.code } }"
                              ></Button>
                              <Button
                                v-show="follow"
                                variant="primary"
                                view="success"
                                shape="square"
                                icon-left="compare-2"
                                mobile
                                @click="compareResult"
                              ></Button>
                            </Col>
                          </Row>
                          <div
                            class="section__item d-none d-md-block"
                            v-if="participantInfo.length"
                          >
                            <div class="divider m-0"></div>
                            <br>
                            <div class="participant-info">
                              <div
                                class="participant-info__item"
                                v-for="({ icon, text }, key) in participantInfo"
                                :key="key"
                              >
                                <span class="text-with-icon">
                                  <Icon
                                    class="icon color-muted"
                                    :name="icon"
                                    size="s"
                                  />
                                  <span class="text-break" v-html="text"></span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col v-if="false">
                  <img src="https://via.placeholder.com/285x238" alt="">
                </Col>
              </Row>
            </div>
          </Col>
          <!-- Profile : END -->
          <Col size="stretch">
            <Row vertical-gutters>
              <Col>
                <div class="container__mobile">
                  <div class="section-block">
                    <!-- Event info : BEGIN -->
                    <div class="section-block__group section-block__group_bordered-bottom">
                      <Row>
                        <Col size="stretch" class="flex-shrink">
                          <h2
                            class="h3"
                            :class="{ 'uppercase': theme === 'bfla' }"
                          >{{ race.name }}</h2>
                          <b
                            class="text-xs text-xs_mt color-muted text-break"
                            :class="{ 'uppercase': theme === 'bfla' }"
                          >
                            {{ event.name }}
                          </b>
                        </Col>
                        <Col size="auto"></Col>
                        <Col size="auto" class="d-md-none" v-show="race.waterTemperature != null">
                          <Button
                            shape="square"
                            variant="secondary"
                            icon-left="info-circle"
                            mobile
                            @click="$refs.raceInfo.open()"
                          ></Button>
                        </Col>
                        <Col size="auto" class="d-none d-md-block" v-if="race.waterTemperature != null">
                          <div class="text-xs">{{ $t('event_results.water_t') }}</div>
                          <div class="text-with-icon text-nowrap">
                            <span><b>{{ race.waterTemperature }}°C</b></span>
                            <img v-show="race.wetsuitAllowed" class="icon" src="@/assets/img/diving-suit.svg" alt="" width="18" height="18">
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <!-- Event info : END -->
                    <!-- Not started state : BEGIN -->
                    <div class="section-block__group" v-if="isDNS">
                      <EmptyState
                        :img="themeImages['bus-stop']"
                        :title="isRelay ? $t('participant.dns_team_title') : $t('participant.dns_title')"
                      ></EmptyState>
                    </div>
                    <!-- Not started state: END -->
                    <!-- DSQ state: BEGIN -->
                    <div class="section-block__group" v-else-if="isDSQ">
                      <EmptyState
                        :img="themeImages['coach']"
                        :title="isRelay ? $t('participant.dsq_team_title') : $t('participant.dsq_title')"
                      ></EmptyState>
                    </div>
                    <!-- DSQ state: END -->
                    <!-- DNF state: BEGIN -->
                    <div class="section-block__group" v-else-if="isDNF">
                      <EmptyState
                        :img="themeImages['taken']"
                        :title="isRelay ? $t('participant.dnf_team_title') : $t('participant.dnf_title')"
                      ></EmptyState>
                    </div>
                    <!-- DNF state: END -->
                    <!-- Result: BEGIN -->
                    <div class="section-block__group text-center" v-else>
                      <div class="text-sm_mt"></div>
                      <!-- Result (mono) : BEGIN -->
                      <Row class="participant-page__result" align="center" justify="space-around">
                        <template v-if="!isComplex && isFinished">
                          <Col :size="{ md: '12' }" class="participant-page__result-item" v-if="result.individualResult">
                            <div class="text-with-icon d-block">
                              <Icon name="stopwatch" size="xxl" class="color-muted"></Icon>&nbsp;
                              <span
                                class="h1"
                                :class="calcMethod === ResultCalcMethod.Individual && successClass"
                              >
                                {{ result.individualTimeResultOffset || '--' }}<b
                                v-if="result.individualDelay"
                                class="text-sm color-muted"
                                style="position: absolute"
                              > &nbsp;+{{ result.individualDelay }}</b>
                              </span>
                            </div>
                          </Col>
                          <Col :size="{ md: '12' }" class="participant-page__result-item" v-if="result.absoluteResult">
                            <div class="text-with-icon d-block">
                              <Icon name="gun" size="xxl" class="color-muted"></Icon>&nbsp;
                              <span
                                class="h1"
                                :class="calcMethod === ResultCalcMethod.Absolute && successClass"
                              >
                                {{ result.absoluteTimeResultOffset || '--' }}<b
                                v-if="result.absoluteDelay"
                                class="text-sm color-muted"
                                style="position: absolute"
                              > &nbsp;+{{ result.absoluteDelay }}</b>
                              </span>
                            </div>
                          </Col>
                          <Col
                            :size="{ md: '12' }"
                            class="participant-page__result-item"
                            v-if="paceOrSpeed"
                          >
                            <div
                              class="h1"
                              v-html="formatValueText(paceOrSpeed, 'subtitle-2')"
                            ></div>
                          </Col>
                        </template>
                      </Row>
                      <!-- Result (mono) : END -->
                      <!-- Result (multi) : BEGIN -->
                      <Col size="auto" class="live-participant-page__result-item" v-if="isComplex || !isFinished">
                        <div
                          class="h1"
                          :class="`${successClass}`"
                        >
                          {{ result.timeResultByCalcMethod || '--' }}<b
                          v-if="result.delayByCalcMethod"
                          class="text-sm color-muted"
                          style="position: absolute"
                        > &nbsp;+{{ result.delayByCalcMethod }}</b>
                        </div>
                      </Col>
                      <!-- Result (multi) : BEGIN -->
                      <div class="text-sm_mt"></div>
                    </div>
                    <!-- Result: END -->
                    <!-- Position : BEGIN -->
                    <div class="section-block__group section-block__group_bordered-top" v-if="isFinished && positions.length">
                      <Row class="section-block__items text-center">
                        <Col
                          class="section-block__item"
                          :size="{ sm: 'stretch' }"
                          v-for="(position, i) in positions"
                          :key="i"
                        >
                          <div class="text-nowrap">
                            <span class="h3">{{ position.value || '--' }}</span> <b class="text-md color-muted">/ {{ position.total || '--' }}</b>
                          </div>
                          <div class="text-xs mt-4"><b v-html="position.title"></b></div>
                        </Col>
                      </Row>
                    </div>
                    <!-- Checkpoints progress : BEGIN -->
                    <div class="section-block__group section-block__group_bordered-top" v-if="isFinished || isDNF">
                      <Progress
                        :value="getFinishedPointsLength(substages)"
                        :total="substages.length"
                        use-points
                      >
                        <template v-slot:points>
                          <span
                            v-for="(point, i) in substages"
                            :key="i"
                            :class="{ 'active': !point.isMissed }"
                            :title="point.name"
                          ></span>
                        </template>
                        <template v-slot:caption>
                          {{ $t('participant.checkpoints_finished') }}:
                        </template>
                        <template v-slot:top="">
                          <Row
                            class="progress__values-top d-none d-md-flex"
                            align="center"
                            justify="space-between"
                          >
                            <Col size="auto">
                              <div class="text-with-icon text-lg">
                                <Icon
                                  class="icon color-muted"
                                  name="flag"
                                  size="l"
                                />&nbsp;<span><b>{{ startTime }}</b></span>
                              </div>
                            </Col>
                            <Col size="auto">
                              <div class="text-with-icon text-lg">
                                <span><b>{{ endTime || $reversedEnum(ResultStatus)[status] || '--' }}</b></span>&nbsp;<Icon
                                class="icon color-muted"
                                name="finish"
                                size="l"
                              />
                              </div>
                            </Col>
                          </Row>
                        </template>
                        <template v-slot:bottom="">
                          <div class="progress__values-bottom d-md-none">
                            <Row align="center" justify="space-between">
                              <Col size="auto">
                                <b class="text-xs">{{ $t('participant.start') }}</b>
                              </Col>
                              <Col size="auto">
                                <b>{{ startTime }}</b>
                              </Col>
                            </Row>
                            <Row align="center" justify="space-between">
                              <Col size="auto">
                                <b class="text-xs">{{ $t('participant.finish') }}</b>
                              </Col>
                              <Col size="auto">
                                <b>{{ endTime || $reversedEnum(ResultStatus)[status] || '--' }}</b>
                              </Col>
                            </Row>
                          </div>
                        </template>
                      </Progress>
                    </div>
                    <!-- Checkpoints progress : END -->
                    <!-- Assets : BEGIN -->
                    <div
                      class="section-block__group section-block__group_bordered-top"
                      v-if="isFinished && (assets.video || assets.diploma || assets.photo)"
                    >
                      <Row vertical-gutters>
                        <Col :size="{ md: 'stretch' }" v-if="assets.video">
                          <Button
                            variant="secondary"
                            icon-left="video"
                            :text="$t('result.video')"
                            size="lg"
                            mobile
                            wide
                            @click="openVideoFinish(assets.video)"
                          ></Button>
                        </Col>
                        <Col :size="{ md: 'stretch' }" v-if="assets.diploma">
                          <Button
                            variant="secondary"
                            icon-left="solution"
                            :text="$t('result.certificate')"
                            size="lg"
                            :href="assets.diploma"
                            target="_blank"
                            mobile
                            wide
                          ></Button>
                        </Col>
                        <Col :size="{ md: 'stretch' }" v-if="assets.photo">
                          <Button
                            variant="secondary"
                            icon-left="camera"
                            :text="$t('result.photos')"
                            size="lg"
                            :href="assets.photo"
                            target="_blank"
                            mobile
                            wide
                          ></Button>
                        </Col>
                      </Row>
                    </div>
                    <!-- Assets : END -->
                  </div>
                </div>
              </Col>
              <Col v-if="(isFinished || isDNF) && stages.length">
                <div class="container__mobile">
                  <div class="section-block">
                    <div class="section-block__group">
                      <StageCard
                        v-for="(stage, i) in stages"
                        :key="i"
                        v-bind="getStageCardProps(stage)"
                      >
                        <div class="table-container" v-if="stage.stages.length">
                          <table class="table table_default table_striped">
                            <colgroup>
                              <col width="180">
                            </colgroup>
                            <thead class="table__header">
                            <tr>
                              <th>{{ $t('result.point') }}</th>
                              <th class="text-center">{{ $t('result.result') }}</th>
                              <th class="text-center">{{ $t('result.time') }}</th>
                              <th class="text-center" v-if="stage.disciplineCode === 'cycling'">{{ $t('result.speed') }}</th>
                              <th class="text-center" v-else>{{ $t('result.pace') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                              v-for="point in stage.stages"
                              :key="point.stageId"
                              :class="{ 'color-muted': point.isMissed }"
                            >
                              <td class="text-sm"><b>{{ point.name }}</b></td>
                              <td class="text-center">
                                <TimeOffset
                                  v-if="point.timeOffset && point.timeOffset !== '00:00'"
                                  :offset="point.timeOffset"
                                ></TimeOffset>
                                <span class="v-align-middle simibold">{{ point.resultByCalcMethod || '--' }}</span>
                              </td>
                              <td class="text-center simibold">
                                {{
                                  point.stageTime || '--'
                                }}
                              </td>
                              <td
                                v-if="stage.disciplineCode === 'cycling'"
                                class="text-center simibold"
                                v-html="formatValueText(point.speed, 'd-none') || '--'"
                              ></td>
                              <td
                                v-else
                                class="text-center simibold"
                                v-html="formatValueText(point.pace, 'd-none') || '--'"
                              ></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <template
                          v-slot:footer
                          v-if="stage.disciplineCode === 'cycling' ? stage.speed : stage.pace">
                          <div
                            v-if="stage.disciplineCode === 'cycling'"
                            class="text-right"
                          >
                            <span class="text-xs">{{ $t('result.average_speed') }}</span>
                            <b v-html="formatValueText(stage.speed)"></b>
                          </div>
                          <div
                            v-else
                            class="text-right"
                          >
                            <span class="text-xs">{{ $t('result.average_pace') }}</span>
                            <b v-html="formatValueText(stage.pace)"></b>
                          </div>
                        </template>
                      </StageCard>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
    <Modal
      name="raceInfo"
      ref="raceInfo"
      :title="event.name"
      :subtitle="race.name"
    >
      <div class="info-card" v-show="race.waterTemperature != null">
        <img class="info-card__img" src="@/assets/img/info-water.svg" alt="">
        <div class="info-card__title">{{ $t('event_results.water_t') }}</div>
        <div class="text-with-icon text-nowrap">
          <span><b>{{ race.waterTemperature || '--' }}°C</b></span>
          <img v-show="race.wetsuitAllowed" class="icon" src="@/assets/img/diving-suit.svg" alt="" width="18" height="18">
        </div>
      </div>
    </Modal>
    <VideoFinish ref="videoFinish"></VideoFinish>
    <ShareModal
      name="participantShareModal"
      :title="$t('share_modal.title')"
      :subtitle="$t('share_modal.subtitle')"
      :img="themeImages['mobile-marketing']"
    ></ShareModal>
    <slot></slot>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ResultStatus, ResultCalcMethod } from '@/enums';
import { formatPaceText } from '@/utils';
import Header from '@/sections/Header/HeaderExternalBack.vue';
import Avatar from '@/components/Avatar/Avatar.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import Progress from '@/components/Progress/Progress.vue';
import StageCard from '@/components/StageCard/StageCard.vue';
import Modal from '@/components/Modal/Modal.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import VideoFinish from '@/sections/VideoFinish/VideoFinish.vue';
import ShareModal from '@/sections/ShareModal/ShareModal.vue';
import TimeOffset from '@/components/TimeOffset/TimeOffset.vue';

export default {
  name: 'ParticipantDetails',
  components: {
    Header,
    Avatar,
    EmptyState,
    Progress,
    StageCard,
    Modal,
    Dropdown,
    VideoFinish,
    ShareModal,
    TimeOffset,
  },
  props: {
    isLoading: Boolean,
    isRelay: Boolean,
    isComplex: Boolean,
    calcMethod: Number,
    status: Number,
    event: {
      type: Object,
      default: () => ({
        id: null,
        code: null,
        name: null,
        url: null,
      }),
    },
    race: {
      type: Object,
      default: () => ({
        id: null,
        code: null,
        name: null,
        waterTemperature: null,
        wetsuitAllowed: false,
      }),
    },
    participant: {
      type: Object,
      default: () => ({
        id: null,
        name: null,
        avatar: null,
        startNumber: null,
        category: null,
      }),
    },
    participantInfo: {
      type: Array,
      default: () => ([]),
    },
    participants: {
      type: Array,
      default: () => [],
    },
    result: {
      type: Object,
      default: () => ({}),
    },
    positions: {
      type: Array,
      default: () => [],
    },
    assets: {
      type: Object,
      default: () => ({
        video: null,
        diploma: null,
        photo: null,
      }),
    },
    stages: {
      type: Array,
      default: () => [],
    },
    substages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ResultStatus,
      ResultCalcMethod,
    };
  },
  computed: {
    ...mapGetters('follow', ['getFollowListOfRace']),
    ...mapGetters('settings', ['theme', 'themeImages']),
    raceCompareList() {
      return this.getFollowListOfRace({
        eventCode: this.event.code,
        raceId: this.race.id,
      });
    },
    successClass() {
      const successClasses = {
        samarkand: 'samarkand-color-success',
        rzd: 'rzd-color-success',
        rr: 'color-success',
        pionercup: 'pionercup-color-primary',
        legkovrace: 'legkovrace-color-primary',
        tulamarathon: 'tulamarathon-color-primary',
      };

      return successClasses[this.theme];
    },
    follow() {
      return this.raceCompareList.indexOf(this.participant.id) !== -1;
    },
    isFinished() {
      return this.status === ResultStatus.Finish;
    },
    isDNS() {
      return this.status === ResultStatus.DNS;
    },
    isDNF() {
      return this.status === ResultStatus.DNF;
    },
    isDSQ() {
      return this.status === ResultStatus.DSQ;
    },
    startTime() {
      let time = null;
      this.substages.some((substage) => {
        time = substage.stageTime;
        return !!time;
      });
      return time;
    },
    endTime() {
      return this.substages[this.substages.length - 1]?.stageTime;
    },
    backLink() {
      return {
        name: 'Results',
        params: {
          raceCode: this.race.code,
          eventCode: this.event.code,
        },
      };
    },
    paceOrSpeed() {
      return this.race.disciplineCode === 'cycling'
        ? this.result.speed
        : this.result.pace;
    },
    backButtonProps() {
      const propsDict = {
        rzd: {
          shape: 'circle',
          variant: 'secondary',
          'icon-size': 'm',
          'icon-left': 'left',
        },
      };

      return propsDict[this.theme];
    },

    visibilityCompareButton() {
      const disableOnTenants = ['pionercup'];
      return disableOnTenants.includes(this.theme);
    },
  },
  methods: {
    ...mapActions('event', ['getEvent', 'getRaceInfo']),
    ...mapActions('follow', ['toggleFollow']),
    openVideoFinish(video) {
      this.$refs.videoFinish.open(video);
    },
    formatValueText: formatPaceText,
    getFinishedPointsLength(points) {
      if (!points) {
        return 0;
      }
      return points.filter((o) => !o.isMissed).length;
    },
    getStageCardProps(stage) {
      const isStage = stage.stages.length;
      const common = {
        participant: isStage ? stage.participant : null,
        isRelay: this.isRelay,
        isDNF: this.isDNF,
        isAbsoluteTime: this.calcMethod === 1,
        timeOffset: stage.timeOffset,
      };

      return !this.isComplex && !this.isRelay
        ? {
          title: stage.name,
          disciplineCode: stage.disciplineCode,
          positionByCategory: this.result.categoryPosition,
          positionByGender: this.result.genderPosition,
          position: this.result.position,
          result: this.result.resultByCalcMethod,
          finished: this.isFinished,
          ...common,
        }
        : {
          title: stage.name,
          disciplineCode: stage.stages.length ? stage.disciplineCode : null,
          positionByCategory: stage.genderAgePosition || stage.genderAgeStagePosition,
          positionByGender: stage.genderPosition,
          position: stage.position,
          result: stage.resultByCalcMethod,
          finished: stage.isMissed
            ? false
            : !stage.stages[stage.stages.length - 1]?.isMissed,
          ...common,
        };
    },
    compareResult() {
      this.toggleFollow({
        id: this.participant.id,
        eventCode: this.event.code,
        raceId: this.race.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./ParticipantDetails";
</style>
